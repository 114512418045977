<template>
    <nav>
        <ul class="pagination">
            <li :class="['page-item', { disabled: isOnFirstPage }]">
                <a @click.prevent="loadPage(1)" class="page-link"><span>&laquo;</span></a>
            </li>
            <li :class="['page-item', { disabled: isOnFirstPage }]">
                <a @click.prevent="loadPage('prev')" class="page-link"><span>&lt;</span></a>
            </li>

            <template v-if="notEnoughPages">
                <li v-for="n in totalPage" :key="n" :class="['page-item', { active: isCurrentPage(n) }]">
                    <a @click.prevent="loadPage(n)" class="page-link">{{ n }}</a>
                </li>
            </template>
            <template v-else>
                <li v-for="n in windowSize" :key="n" :class="['page-item', { active: isCurrentPage(windowStart + n - 1) }]">
                    <a @click.prevent="loadPage(windowStart + n - 1)" class="page-link">{{ windowStart + n - 1 }}</a>
                </li>
            </template>

            <li :class="['page-item', { disabled: isOnLastPage }]">
                <a @click.prevent="loadPage('next')" class="page-link" href=""><span>&gt;</span></a>
            </li>
            <li :class="['page-item', { disabled: isOnLastPage }]">
                <a @click.prevent="loadPage(totalPage)" class="page-link" href=""><span>&raquo;</span></a>
            </li>
        </ul>
    </nav>
</template>

<script>
import VuetablePaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin';

export default {
    mixins: [VuetablePaginationMixin],
};
</script>
