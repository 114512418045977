import Layout from '@/layout/Layout.vue';
import { AdminRole } from '@/globals';

export default [
    {
        path: '/users',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "users" */ '@/components/users/users-list'),
                name: 'UsersList',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "users" */ '@/components/users/user-create'),
                name: 'UserCreate',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "users" */ '@/components/users/user-edit'),
                name: 'UserEdit',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
        ],
    },
];
