<template>
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
        <div class="container-fluid container-p-x py-3 text-right">
            {{ buildVersion }}
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'app-layout-footer',

    methods: {
        getLayoutFooterBg() {
            return `bg-${this.layoutFooterBg}`;
        },
    },
    computed: {
        ...mapGetters({
            buildVersion: 'buildVersion',
        }),
    },
};
</script>
