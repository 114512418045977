import layoutHelpers from '@/layout/helpers.js';

export const AdminRole = {
    SUPER: 'super',
};

export default function () {
    return {
        globalPurchaseHistoryStatuses: [
            { status: 'blocked', description: 'Заблокировано. Статус публикации: "Снято с публикации"' },
            {
                status: 'canceled',
                description: 'Отменено Заказчиком. Статус извещения: "Закупка отменена"',
            },
            { status: 'change_length_to_normal_and_signed', description: 'Закупка переведена в несрочную' },
            {
                status: 'contract_concluded',
                description: 'Договор заключен',
            },
            { status: 'contract_executed', description: 'Договор исполнен' },
            { status: 'contract_not_executed', description: 'Договор не исполнен' },
            {
                status: 'control',
                description: 'Направлено в Контроль УО',
            },
            { status: 'created', description: 'Создан черновик' },
            { status: 'external_contract_concluded', description: 'Заключен договор вне ИС' },
            {
                status: 'external_published',
                description: 'Опубликована закупка вне ИС',
            },
            { status: 'failed', description: 'Торги не состоялись по причине отсутствия участников' },
            {
                status: 'prolonging',
                description: 'Торги продлены по причине отсутствия участников',
            },
            { status: 'customer_prolonging', description: 'Закупочная сессия продлена заказчиком' },
            {
                status: 'protocol_answer_disagreement',
                description: 'Заказчик направил поставщику ответ на протокол разногласий',
            },
            { status: 'protocol_concluded', description: 'Договор заключен' },
            {
                status: 'protocol_disagreement',
                description: 'Поставщик направил заказчику протокол разногласий',
            },
            { status: 'protocol_not_concluded', description: 'Договор не заключен' },
            {
                status: 'protocol_ready',
                description: 'Сформирован итоговый протокол закупочной сессии',
            },
            { status: 'published', description: 'Опубликовано. Статус извещения: "Идут торги"' },
            { status: 'unpublished', description: 'Снято с публикации для редактирования' },
            {
                status: 'signed',
                description: 'Сохранено и подписано ЭЦП Заказчика',
            },
            { status: 'unblock', description: 'Разблокировано. Статус публикации: "Черновик"' },
            {
                status: 'customer_sent_documents_to_provider',
                description: 'Заказчик направил поставщику договор на подписание',
            },
            { status: 'provider_signed_contract', description: 'Поставщик подписал договор' },
            {
                status: 'customer_signed_contract',
                description: 'Заказчик подписал договор',
            },
            { status: 'execution_contract_concluded', description: 'Заказчик изменил статус договора на "Договор заключен"' },
            {
                status: 'execution_contract_executed',
                description: 'Заказчик изменил статус договора на "Договор исполнен"',
            },
            { status: 'execution_contract_not_executed', description: 'Заказчик изменил статус договора на "Договор не исполнен"' },
            {
                status: 'execution_contract_terminated',
                description: 'Заказчик изменил статус договора на "Договор расторгнут"',
            },
            { status: 'supplementary_agreement_sent_to_provider', description: 'Дополнительное соглашение направлено поставщику для подписания' },
            {
                status: 'supplementary_agreement_dispute',
                description: 'Поставщик направил протокол разногласий к дополнительному соглашению',
            },
            {
                status: 'supplementary_agreement_dispute_reply',
                description: 'Заказчик направил поставщику ответ на протокол разногласий к дополнительному соглашению',
            },
            { status: 'supplementary_agreement_signed_by_provider', description: 'Дополнительное соглашение подписано поставщиком' },
            {
                status: 'supplementary_agreement_signed_by_customer',
                description: 'Дополнительное соглашение подписано заказчиком',
            },
            { status: 'supplementary_agreement_concluded', description: 'Заключено дополнительное соглашение к договору' },
        ],
        globalApprovalsHistoryStatuses: [
            { status: 1, description: 'Разрешено' },
            { status: 2, description: 'Запрещено' },
            { status: 3, description: 'Разрешено (проигнорировано)' },
            {
                status: 4,
                description: 'Запрещено (проигнорировано)',
            },
        ],
        globalApprovalsHistoryTypes: [
            { type: 'external', description: 'Согласование внесистемной закупки' },
            { type: 'payment', description: 'Согласование оплаты' },
            {
                type: 'termination',
                description: 'Согласование расторжения договора',
            },
        ],

        AdminRole: AdminRole,

        /**
         * Check if user is permitted
         *
         * @param {array} userRoles
         * @returns {boolean}
         */
        userRoleIsPermitted(userRoles) {
            if (typeof this.$store.state.user.role !== 'undefined') {
                return userRoles.includes(this.$store.state.user.role);
            } else {
                return false;
            }
        }, // Public url
        publicUrl: process.env.BASE_URL,

        // Layout helpers
        layoutHelpers,

        // Check for RTL layout
        get isRTL() {
            return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl';
        },

        // Check if IE
        get isIEMode() {
            return typeof document['documentMode'] === 'number';
        },

        // Check if IE10
        get isIE10Mode() {
            return this.isIEMode && document['documentMode'] === 10;
        },

        // Layout navbar color
        get layoutNavbarBg() {
            return 'navbar-theme';
        },

        // Layout sidenav color
        get layoutSidenavBg() {
            return 'sidenav-theme';
        },

        // Layout footer color
        get layoutFooterBg() {
            return 'footer-theme';
        },

        // Animate scrollTop
        scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
            if (element.scrollTop === to) return;
            const start = element.scrollTop;
            const change = to - start;
            const startDate = +new Date();

            // t = current time; b = start value; c = change in value; d = duration
            const easeInOutQuad = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            };

            const animateScroll = () => {
                const currentDate = +new Date();
                const currentTime = currentDate - startDate;
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    element.scrollTop = to;
                }
            };

            animateScroll();
        },
    };
}
