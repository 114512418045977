<template>
    <div class="py-2">
        <b-row>
            <b-col md="6">
                <h4>{{ title }}</h4>
            </b-col>
            <b-col md="6"> </b-col>
        </b-row>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FormContentGroup',
    props: {
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            default: null,
        },
        imgAlt: {
            type: String,
            default: null,
        },
        grey: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
