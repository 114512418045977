export default {
    computed: {
        $globalDictionaries() {
            return this.$store.getters['globalDictionaries/globalDictionaries'];
        },
    },
    mounted() {
        if (this.$store.getters['isLoggedIn'] && this.$store.state.globalDictionaries.updatedAt === null && this.$store.state.globalDictionaries.isUpdating === false) {
            this.$store.dispatch('globalDictionaries/populateGlobalDictionaries');
        }
    },
};
