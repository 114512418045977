<template>
    <b-col md="4">
        <b-form-group :label="label">
            <multiselect v-model="text" :options="options" :multiple="false" :allow-empty="allowEmpty" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :show-labels="false" :label="labelField" :track-by="trackBy" :disabled="disabled" :id="id" />
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'filter-select-single',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.find((e) => e[this.trackBy] === this.value) : null;
            },
            set(value) {
                this.$emit('input', value ? value[this.trackBy] : null);
            },
        },
        id() {
            return 'filter-select-single_' + this._uid;
        },
    },
};
</script>
