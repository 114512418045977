import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createLogger from 'vuex/dist/logger';
import storePlugins from '@/plugins/storePlugins';

import organizationsModule from './organizations';
import usersModule from './users';
import adminsModule from './admins';
import globalDictionariesModule from '@/store/globalDictionaries';
import competitivePurchasesModule from '@/store/competitivePurchases';
import directPurchasesModule from '@/store/directPurchases';
import simplePurchasesModule from '@/store/simple-purchases';
import accountsModule from '@/store/accounts';
import accountDetailsModule from '@/store/accountDetails';
import refundRequestsModule from './refundRequests';

import { v4 as uuidv4 } from 'uuid';

const debug = process.env.NODE_ENV !== 'production';
Vue.use(Vuex);

const buildOptions = (text = null, title = null, variant = 'orange', boldText = null, link = null, timer = 10000, linkText = null) => {
    return {
        title: title,
        variant: variant,
        boldText: boldText,
        text: text,
        link: link,
        timer: timer,
        linkText: linkText,
    };
};

const store = new Vuex.Store({
    state: {
        packageVersion: process.env.__BUILD_STRING__ || '',
        status: '',
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') !== '' && localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : '{}',
        toasts: [],
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
            localStorage.setItem('token', token);
        },
        auth_error(state) {
            state.status = 'error';
            state.token = '';
        },
        logout(state) {
            state.status = '';
            state.token = '';
            state.user = {};
            this.commit('organizations/resetFilter');
            this.commit('organizations/resetPagination');
            this.commit('users/resetFilter');
            this.commit('users/resetPagination');
            this.commit('admins/resetFilter');
            this.commit('admins/resetPagination');
            this.commit('competitivePurchases/resetFilter');
            this.commit('competitivePurchases/resetPagination');
            this.commit('directPurchases/resetFilter');
            this.commit('directPurchases/resetPagination');
            this.commit('simplePurchases/resetFilter');
            this.commit('simplePurchases/resetPagination');
            this.commit('account/resetFilter');
            this.commit('account/resetPagination');
            this.commit('accountDetails/resetFilter');
            this.commit('accountDetails/resetPagination');
            this.commit('refundRequests/resetFilter');
            this.commit('refundRequests/resetPagination');
        },
        set_global_dictionaries(state, data) {
            this.commit('globalDictionaries/set', data);
        },
        set_user(state, user) {
            state.user = user;
        },
        pushToast(state, payload) {
            state.toasts.unshift(payload);
        },
        removeToastFromStore(state, payload) {
            let index = state.toasts.findIndex((el) => el.id === payload);
            state.toasts.splice(index, 1);
        },
    },
    actions: {
        login({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({
                    url: '/auth/login',
                    data: user,
                    method: 'POST',
                })
                    .then(async (resp) => {
                        const token = resp.data.token;
                        const user = JSON.parse(JSON.stringify(resp.data.user));
                        localStorage.setItem('token', token);
                        localStorage.setItem('user', JSON.stringify(user));
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        commit('auth_success', token);
                        commit('set_user', user);
                        resolve(resp);
                    })
                    .catch((err) => {
                        dispatch('showError', err);
                        commit('auth_error');
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        reject(err);
                    });
            });
        },
        async logout({ commit }) {
            await axios({ url: '/auth/logout', method: 'POST' }).catch(() => {});
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            delete axios.defaults.headers.common['Authorization'];
        },
        getUserData({ commit }) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                axios({
                    url: '/auth/recall',
                    method: 'POST',
                })
                    .then((resp) => {
                        const { user } = resp.data;
                        localStorage.setItem('user', JSON.stringify(user));
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                        commit('auth_success', localStorage.getItem('token'));
                        commit('set_user', user);
                        resolve(resp);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        storeAddToast({ commit }, payload) {
            commit('pushToast', payload);
        },
        removeToast({ commit }, payload) {
            commit('removeToastFromStore', payload);
        },
        pushToast({ dispatch }, payload) {
            payload['id'] = uuidv4();
            dispatch('storeAddToast', payload);
        },
        showSuccessToast({ dispatch }, message) {
            dispatch('pushToast', buildOptions(message, 'Успех', 'green'));
        },
        async showError({ dispatch }, error, timeout = 10000) {
            let errorCode = error.message.replace(/\D+/g, '');
            let errorType = +errorCode[0];
            if (400 === +errorCode) {
                let options = buildOptions(error.response.data.message, 'Ошибка', 'red', null, null, timeout);
                this.pushToast(options);
            } else if (422 === +errorCode) {
                let err = error.response.data.errors;
                for (let key in err) {
                    dispatch('pushToast', buildOptions(err[key].join(', '), 'Ошибка', 'red'));
                }
            } else if (4 === errorType) {
                dispatch('pushToast', buildOptions(error.response.data.message, 'Ошибка'));
            } else if (5 === errorType) {
                dispatch('pushToast', buildOptions('Сервер не отвечает', 'Ошибка'));
            } else {
                dispatch('pushToast', buildOptions('Неизвестная ошибка, обратитесь в тех. поддержку', 'Ошибка'));
            }
        },
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        token: (state) => state.token,
        authStatus: (state) => state.status,
        // can: (state) => (perm) => {
        //     return !!state.user.permissions.find((permission) => permission.name === perm);
        // },
        getToasts: (state) => state.toasts,
        buildVersion: (state) => state.packageVersion,
    },
    strict: debug,
    plugins: debug ? [createLogger(), storePlugins] : [storePlugins], // set logger only for development
    modules: {
        organizations: organizationsModule,
        competitivePurchases: competitivePurchasesModule,
        directPurchases: directPurchasesModule,
        simplePurchases: simplePurchasesModule,
        users: usersModule,
        admins: adminsModule,
        accounts: accountsModule,
        accountDetails: accountDetailsModule,
        globalDictionaries: globalDictionariesModule,
        refundRequests: refundRequestsModule,
    },
});

export default store;
