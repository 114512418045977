<template>
    <div id="app">
        <router-view />
        <div class="toast_box">
            <transition-group name="animate">
                <toast v-for="toast in toasts" :key="'toast_' + toast.id" @hide="removeToast($event)" :toast="toast"></toast>
            </transition-group>
        </div>
    </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-vibrant.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>

<script>
import { mapGetters } from 'vuex';

const titleTemplate = '%s - ИС ЕСТ';

export default {
    name: 'app',
    metaInfo: {
        title: 'Index',
        titleTemplate: titleTemplate,
    },
    updated() {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1);
    },
    created() {
        if (localStorage.getItem('token')) {
            this.$store.dispatch('getUserData');
        }
    },

    computed: {
        ...mapGetters({
            toasts: 'getToasts',
        }),
    },
};
</script>

<style>
.toast_box {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 9999;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.animate-enter-active {
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.animate-leave-active {
    -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -o-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -moz-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.animate-enter {
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}
.animate-leave-to {
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}
</style>
