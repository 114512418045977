<template>
    <sidenav :class="curClasses" :orientation="orientation">
        <div v-if="orientation !== 'horizontal'" class="app-brand demo">
            <span class="app-brand-logo demo bg-primary"> </span>
            <router-link class="app-brand-text demo sidenav-text font-weight-normal ml-2" to="/">ИС ЕСТ</router-link>
            <a class="layout-sidenav-toggle sidenav-link text-large ml-auto" href="javascript:void(0)" @click="toggleSidenav()">
                <i class="ion ion-md-menu align-middle" />
            </a>
        </div>
        <div v-if="orientation !== 'horizontal'" class="sidenav-divider mt-0"></div>

        <!-- Links -->
        <div :class="{ 'py-1': orientation !== 'horizontal' }" class="sidenav-inner">
            <sidenav-router-link :to="{ name: 'AnalyticsDashboard' }" exact icon="ion ion-ios-paper"> Дашборды </sidenav-router-link>
            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN])" :to="{ name: 'NewsList' }" icon="ion ion-ios-clipboard"> Новости</sidenav-router-link>-->

            <!--            <sidenav-menu-->
            <!--                v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR, AdminRole.KRU, AdminRole.GRBS, AdminRole.MUNICIPALITY, AdminRole.MINFIN])"-->
            <!--                :active="isMenuActive('/approvals')"-->
            <!--                :open="isMenuOpen('/approvals')"-->
            <!--                icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Согласования</template>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.GRBS, AdminRole.MUNICIPALITY])" :to="{ name: 'ExternalPurchaseApprovalsList' }" exact>-->
            <!--                    Согласование закупки вне ИС-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.GRBS, AdminRole.MUNICIPALITY])" :to="{ name: 'ContractTerminationApprovalsList' }" exact>-->
            <!--                    Согласование расторжения договора-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link-->
            <!--                    v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR, AdminRole.KRU, AdminRole.GRBS, AdminRole.MUNICIPALITY, AdminRole.MINFIN])"-->
            <!--                    :to="{ name: 'ApprovalsHistoryList' }"-->
            <!--                    exact>-->
            <!--                    Архив согласований-->
            <!--                </sidenav-router-link>-->
            <!--            </sidenav-menu>-->
            <!--            <sidenav-menu v-if="userRoleIsPermitted([AdminRole.SUPER])" :active="isMenuActive('/simple_purchases')" :open="isMenuOpen('/simple_purchases')" icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Простые закупки</template>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'SimplePurchasesAnalytics' }" exact> Аналитика </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'SimplePurchasesList' }" exact> Список закупок </sidenav-router-link>-->
            <!--            </sidenav-menu>-->
            <!--            <sidenav-menu v-if="userRoleIsPermitted([AdminRole.SUPER])" :active="isMenuActive('/competitive_purchases')" :open="isMenuOpen('/competitive_purchases')" icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Конкурсы</template>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'CompetitivePurchasesAnalytics' }" exact> Аналитика </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'CompetitivePurchasesList' }" exact> Список закупок </sidenav-router-link>-->
            <!--            </sidenav-menu>-->
            <sidenav-menu v-if="userRoleIsPermitted([AdminRole.SUPER])" :active="isMenuActive('/competitive_purchases')" :open="isMenuOpen('/competitive_purchases')" icon="ion ion-ios-calculator">
                <template #link-text>Конкурсы</template>
                <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'ExternalPurchasesAnalytics' }" exact>-->
                <!--                    Аналитика-->
                <!--                </sidenav-router-link>-->
                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'CompetitivePurchasesList' }" exact> Список закупок </sidenav-router-link>
            </sidenav-menu>
            <sidenav-menu v-if="userRoleIsPermitted([AdminRole.SUPER])" :active="isMenuActive('/direct_purchases')" :open="isMenuOpen('/direct_purchases')" icon="ion ion-ios-calculator">
                <template #link-text>Прямые закупки</template>
                <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'ExternalPurchasesAnalytics' }" exact>-->
                <!--                    Аналитика-->
                <!--                </sidenav-router-link>-->
                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'DirectPurchasesList' }" exact> Список закупок </sidenav-router-link>
            </sidenav-menu>
            <sidenav-menu v-if="userRoleIsPermitted([AdminRole.SUPER])" :active="isMenuActive('/simple_purchases')" :open="isMenuOpen('/simple_purchases')" icon="ion ion-ios-calculator">
                <template #link-text>Простые закупки</template>
                <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'ExternalPurchasesAnalytics' }" exact>-->
                <!--                    Аналитика-->
                <!--                </sidenav-router-link>-->
                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'SimplePurchasesList' }" exact> Список закупок </sidenav-router-link>
            </sidenav-menu>
            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'OrganizationsList' }" icon="ion ion-ios-clipboard"> Организации</sidenav-router-link>
            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'UsersList' }" icon="ion ion-ios-clipboard"> Пользователи</sidenav-router-link>
            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'AdminsList' }" icon="ion ion-ios-clipboard"> Администраторы</sidenav-router-link>-->
            <!--            <sidenav-menu-->
            <!--                v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR, AdminRole.KRU, AdminRole.GRBS, AdminRole.MUNICIPALITY, AdminRole.MINFIN])"-->
            <!--                :active="isMenuActive('/organizations')"-->
            <!--                :open="isMenuOpen('/organizations')"-->
            <!--                icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Организации</template>-->
            <!--                <sidenav-router-link-->
            <!--                    v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR, AdminRole.KRU, AdminRole.GRBS, AdminRole.MUNICIPALITY, AdminRole.MINFIN, AdminRole.ADMIN])"-->
            <!--                    :to="{ name: 'OrganizationsCustomersList' }"-->
            <!--                    exact>-->
            <!--                    Заказчики-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link-->
            <!--                    v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.KRU, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])"-->
            <!--                    :to="{ name: 'OrganizationsProvidersList' }"-->
            <!--                    exact>-->
            <!--                    Поставщики-->
            <!--                </sidenav-router-link>-->
            <!--            </sidenav-menu>-->
            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'Account' }" icon="ion ion-ios-paper"> Лицевые счета</sidenav-router-link>
            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN])" :to="{ name: 'RefundRequestsList' }" icon="ion ion-ios-paper">-->
            <!--                Запросы на возврат средств-->
            <!--            </sidenav-router-link>-->
            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'UsersList' }" icon="ion ion-ios-paper"> Пользователи</sidenav-router-link>-->
            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'CertificatesList' }" icon="ion ion-ios-paper"> Сертификаты</sidenav-router-link>-->
            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'Calendar' }" icon="ion ion-ios-clipboard"> Календарь</sidenav-router-link>

            <!--            <sidenav-menu-->
            <!--                v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])"-->
            <!--                :active="isMenuActive('/directories')"-->
            <!--                :open="isMenuOpen('/directories')"-->
            <!--                icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Справочники</template>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'CountriesList' }"> Страны</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PurchaseCategoriesList' }"> Предметы закупок</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'RksPurchaseCategoriesList' }"> Предметы закупок РКС</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PaymentTypesList' }"> Виды оплаты</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'NationalProjectList' }">-->
            <!--                    Национальные проекты-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'KtruGroupsList' }">-->
            <!--                    Группы КТРУ-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'KtruItemsList' }">-->
            <!--                    Позиции КТРУ-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'EventTypeCategoriesList' }"> Категории событий в системе</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'EventTypesList' }"> Типы событий в системе</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'OkeisList' }"> ОКЕИ</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN])" :to="{ name: 'OkpdsList' }"> ОКПД2</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PurchaseStagesList' }"> Этапы извещений</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PurchaseStatusesList' }"> Статусы извещений</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'OfferStatusesList' }"> Статусы предложений</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PaymentStatusesList' }"> Статусы оплаты</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'ContractStatusesList' }"> Статусы подписания договора</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'DocumentTypesList' }"> Типы документов и действия</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'OrderTypesList' }"> Типы закупок</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'PaymentTermsList' }"> Условия оплаты</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'OfferDeclineStatusesList' }">-->
            <!--                    Причины отклонения предложений-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'IndustriesList' }"> Отрасли</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'MunicipalitiesList' }"> Муниципалитеты</sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'BudgetaryManagersList' }"> ГРБС</sidenav-router-link>-->
            <!--            </sidenav-menu>-->

            <!--            <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'KtruRequestsList' }" icon="ion ion-ios-paper">-->
            <!--                Запросы на добавление КТРУ-->
            <!--            </sidenav-router-link>-->
            <!--            <sidenav-router-link-->
            <!--                v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])"-->
            <!--                :to="{ name: 'ImportSubstitutionRequestsList' }"-->
            <!--                icon="ion ion-ios-paper">-->
            <!--                Запросы на импортозамещение-->
            <!--            </sidenav-router-link>-->
            <!--            <sidenav-menu-->
            <!--                v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])"-->
            <!--                :active="isMenuActive('/productions')"-->
            <!--                :open="isMenuOpen('/productions')"-->
            <!--                icon="ion ion-ios-calculator">-->
            <!--                <template #link-text>Производство</template>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'ProductionVolumeList' }">-->
            <!--                    Объемы производства-->
            <!--                </sidenav-router-link>-->
            <!--                <sidenav-router-link v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.CONTROL, AdminRole.CONTROL_DIR])" :to="{ name: 'LocalProducersList' }">-->
            <!--                    Местные товаропроизводители-->
            <!--                </sidenav-router-link>-->
            <!--            </sidenav-menu>-->
        </div>
    </sidenav>
</template>

<script>
import { Sidenav, SidenavRouterLink } from '@/vendor/libs/sidenav';
import SidenavMenu from '@/vendor/libs/sidenav/SidenavMenu.vue';
// import SidenavMenu from '@/vendor/libs/sidenav/SidenavMenu.vue';

export default {
    name: 'app-layout-sidenav',
    components: {
        SidenavMenu,
        // SidenavMenu,
        Sidenav,
        SidenavRouterLink,
    },

    props: {
        orientation: {
            type: String,
            default: 'vertical',
        },
    },

    computed: {
        curClasses() {
            let bg = this.layoutSidenavBg;

            if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
                bg = bg.replace(' sidenav-dark', '').replace(' sidenav-light', '').replace('-darker', '').replace('-dark', '');
            }

            return `bg-${bg} ` + (this.orientation !== 'horizontal' ? 'layout-sidenav' : 'layout-sidenav-horizontal container-p-x flex-grow-0');
        },
    },

    methods: {
        isMenuActive(url) {
            return this.$route.path.indexOf(url) === 0;
        },

        isMenuOpen(url) {
            return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal';
        },

        toggleSidenav() {
            this.layoutHelpers.toggleCollapsed();
        },
    },
};
</script>
