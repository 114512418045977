const defaultRefundRequestsFilter = {
    reg_number: '',
    organization_name: '',
};

const defaultPagination = {
    sortOrder: [{ field: 'id', direction: 'desc', sortField: 'id' }],
    page: 1,
};

const refundRequestsModule = {
    namespaced: true,
    state: {
        filter: {
            ...defaultRefundRequestsFilter,
            ...JSON.parse(localStorage.getItem('refundRequestsFilter')),
        },
        pagination: {
            ...defaultPagination,
            ...JSON.parse(localStorage.getItem('refundRequestsPagination')),
        },
    },
    mutations: {
        resetFilter(state) {
            state.filter = defaultRefundRequestsFilter;
        },
        resetPagination(state) {
            state.pagination = defaultPagination;
        },
        updateFilter(state, filter) {
            state.filter = filter;
            localStorage.setItem('refundRequestsFilter', JSON.stringify(filter));
        },
        /**
         * @param {any} state
         * @param {any} pagination
         */
        updatePagination(state, pagination) {
            state.pagination = pagination;
            localStorage.setItem('refundRequestsPagination', JSON.stringify(pagination));
        },
    },
};

export default refundRequestsModule;
