import Layout from '@/layout/Layout.vue';
import { AdminRole } from '@/globals';

export default [
    {
        path: '/organizations',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "organizations" */ '@/components/organizations/organizations-list'),
                name: 'OrganizationsList',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "organizations" */ '@/components/organizations/organization-edit'),
                name: 'OrganizationEdit',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
        ],
    },
];
