<template>
    <div class="py-2">
        <b-row>
            <b-col md="6">
                <h4>{{ title }}</h4>
            </b-col>
            <b-col md="6">
                <file-uploader :is-main="true" :is-disabled="!!document" @set_file="addFile" class="float-right">Загрузить</file-uploader>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div :class="v.$invalid ? 'is-invalid' : ''">
                    <table class="table" v-if="document">
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ document.name }}</td>
                                <td class="text-right">
                                    <b-button @click="removeFile(0, true)" variant="danger" size="sm"> Удалить </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table" v-else>
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" align="center">Не добавлено документов</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="v.$invalid" class="text-danger">Прикрепите документ</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'FormRowSingleDocumentUpload',
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
        },
        v: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        grey: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: [],
        };
    },
    methods: {
        addFile(file) {
            this.document = file;
        },
        removeFile() {
            this.document = null;
        },
    },
    computed: {
        document: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}
.table {
    margin-bottom: 0;
}
</style>
