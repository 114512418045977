<template>
    <b-row class="py-2" no-gutters v-if="value">
        <b-col md="3" class="fs-14 grey-color font-weight-bold"> {{ label }}: </b-col>
        <b-col md="9" class="fs-14 grey-color">
            <b-button variant="link" size="xs" @click="$commonDocumentDownload(value)">{{ value.name }}</b-button>
        </b-col>
    </b-row>
</template>

<script>
import DownloadFiles from '@/services/api/DownloadFiles';

export default {
    name: 'text-row-main-document',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            default: undefined,
        },
    },
    methods: {
        downloadFile(id, name) {
            DownloadFiles.downloadFile(id, name);
        },
    },
};
</script>
