<template>
    <b-modal ref="ktru-select-modal" title="Поиск ТРУ" size="xl" scrollable centered no-close-on-esc no-close-on-backdrop hide-footer @close="cancelKtruSelectModal" @cancel="cancelKtruSelectModal" :visible="show">
        <b-row>
            <b-col md="4">
                <b-form-group label="Рег.номер">
                    <b-input-group>
                        <b-form-input v-model="filter.reg_number" @change="onKtruFilterChange" placeholder="Введите рег.номер" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter.reg_number"
                                @click="
                                    filter.reg_number = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Название">
                    <b-input-group>
                        <b-form-input v-model="filter.name" @change="onKtruFilterChange" placeholder="Введите название" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter.name"
                                @click="
                                    filter.name = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Спецификация">
                    <b-input-group>
                        <b-form-input v-model="filter.specs" @change="onKtruFilterChange" placeholder="Введите текст спецификации" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter.specs"
                                @click="
                                    filter.specs = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Код ОКПД2">
                    <b-input-group>
                        <b-form-input v-model="filter.okpd_code" @change="onKtruFilterChange" placeholder="Введите код ОКПД2 или его часть" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter.okpd_code"
                                @click="
                                    filter.okpd_code = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="table-responsive">
            <vuetable
                ref="ktruvuetable"
                :fields="ktruTableColumns"
                :css="vuetable2Config.table"
                :sort-order="sortOrder"
                :load-on-start="true"
                :query-params="ktruTableQueryParams"
                :append-params="filter"
                :http-fetch="ktruFetchData"
                @vuetable:pagination-data="onKtruPaginationData"
                api-url="/directories/ktru_items"
                data-path="data"
                pagination-path="meta"
                no-data-template="Данные не найдены">
                <template #specs="props">
                    <ul>
                        <li v-for="(item, key) in props.rowData.specs" v-bind:key="item + key">
                            <span style="font-weight: 900">{{ item.Name }}</span
                            >: {{ item.Value }}
                        </li>
                    </ul>
                </template>
                <template #actions="props">
                    <b-btn size="sm" variant="primary" @click="selectKtruItem(props.rowData)">Выбрать</b-btn>
                </template>
            </vuetable>
        </div>
        <div class="float-right d-flex">
            <vuetable-pagination ref="ktrupagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onKtruChangePage" />
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    name: 'KtruSelectModal',
    props: ['show'],
    data() {
        return {
            selectedKtruItem: null,
            ktruTableColumns: [
                { name: 'reg_number', sortField: 'reg_number', title: 'Рег.номер', width: '150px' },
                { name: 'okpd_code', title: 'ОКПД2', width: '150px' },
                { name: 'name', sortField: 'name', title: 'Название', width: '300px' },
                { name: 'specs', title: 'Спецификация' },
                { name: 'actions', title: 'Действия', width: '100px' },
            ],
            sortOrder: [{ field: 'id', direction: 'asc' }],
            filter: {
                name: '',
                reg_number: '',
                specs: '',
                okpd_code: '',
            },
        };
    },
    methods: {
        selectKtruItem(selectedItem) {
            this.$emit('select', selectedItem);
        },
        ktruTableQueryParams(sortOrder, currentPage, perPage) {
            return {
                sortField: sortOrder[0].sortField,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onKtruPaginationData(paginationData) {
            this.$refs.ktrupagination.setPaginationData(paginationData);
        },
        onKtruChangePage(page) {
            this.$refs.ktruvuetable.changePage(page);
        },
        ktruFetchData(apiUrl, httpOptions) {
            return axios.get(apiUrl, httpOptions);
        },
        onKtruFilterChange() {
            this.$refs.ktruvuetable.refresh();
        },
        cancelKtruSelectModal() {
            this.selectedKtruItem = null;
            this.$emit('cancel');
        },
    },
};
</script>
