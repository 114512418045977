<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <the-mask :id="id" mask="+# (###) ###-##-##" :class="'form-control fs-14 ' + (v !== null ? (state ? 'is-valid' : 'is-invalid') : '')" v-model="text" :masked="false" :disabled="disabled"></the-mask>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
    name: 'form-row-edit-phone',
    components: {
        TheMask,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('value', value);
            },
        },
        id() {
            return 'form_row_edit_phone_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения. Формат: +7-(ХХХ) ХХХ-ХХ-ХХ';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.phone && !!this.v.$params.phone) return 'Проверьте правильность заполнения номера телефона. Формат: +7-(ХХХ) ХХХ-ХХ-ХХ';
            }
            return '';
        },
    },
};
</script>
