import moment from 'moment-timezone';
import 'moment/locale/ru';
import DownloadFiles from '@/services/api/DownloadFiles';

export default {
    methods: {
        $formatPrice(value) {
            let val = (value / 100).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        $parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})/, '+$1 ($2) ');
            return '-';
        },
        getCurrentYear() {
            return new Date().getFullYear();
        },
        getCurrentDate() {
            return [new Date().getFullYear(), (new Date().getMonth() + 1 > 9 ? '' : '0') + (new Date().getMonth() + 1), (new Date().getDate() + 1 > 9 ? '' : '0') + (new Date().getDate() + 1)].join('-');
        },
        getDateFormat(data) {
            return moment.tz(data, 'Asia/Bishkek').format('DD.MM.YYYY');
        },
        getDateTimeFormat(data) {
            let newData = moment.tz(data, 'Asia/Bishkek');
            return newData.format('DD.MM.YYYY') + ' ' + newData.format('HH:mm');
        },
        getDateTimeFormatSeconds(data) {
            if (!data) {
                return null;
            }
            let newData = moment.tz(data, 'Asia/Bishkek');
            return newData.format('DD.MM.YYYY') + ' ' + newData.format('HH:mm:ss');
        },

        isObject(item) {
            return item && typeof item === 'object' && !Array.isArray(item);
        },
        boolToText(value) {
            return value ? 'Да' : 'Нет';
        },
        $documentDownload(file) {
            DownloadFiles.documentDownload(file);
        },
        $commonDocumentDownload(file) {
            DownloadFiles.commonDocumentDownload(file);
        },
        $getAttachmentDownloadLink(id) {
            const baseUrl = process.env.VUE_APP_API_BASE_URL + '/api/front/v1';
            return `${baseUrl}/attachments/${id}`;
        },
    },
};
