<template>
    <b-row no-gutters>
        <b-form-group :label="label" :state="v ? state : null" class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
            <date-time-picker
                :id="id"
                :class="v ? (!state ? 'is-invalid' : 'is-valid') : null"
                :error="v ? !state : false"
                v-model="date"
                label="Выберите дату и время"
                :only-date="false"
                formatted="DD.MM.YYYY HH:mm"
                format="YYYY-MM-DD HH:mm:ss"
                buttonNowTranslation="Сегодня"
                placeholder=""
                :min-date="minDate"
                :max-date="maxDate"
                :no-label="!!date"
                :disabled="disabled" />
            <template v-if="v && !state">
                <b-form-invalid-feedback style="display: block !important">Поле обязательно для заполнения</b-form-invalid-feedback>
            </template>
        </b-form-group>
    </b-row>
</template>

<script>
export default {
    name: 'FormRowEditDatetime',
    props: {
        value: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'label',
        },
        minDate: {
            type: String,
            default: null,
        },
        maxDate: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
        id() {
            return 'form-row-edit-datetime_' + this._uid;
        },
    },
};
</script>
