<template>
    <div>
        <b-overlay :show="reportLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
            <b-dropdown boundary="window" text="Аналитические выгрузки (согласно фильтра)" style="margin: auto">
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_budgetary_recipients', filename: 'Аналитика_по_ПБС', filter, thousands: false })"> По ПБС, руб. </b-dropdown-item>
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_budgetary_recipients', filename: 'Аналитика_по_ПБС', filter, thousands: true })"> По ПБС, тыс.руб. </b-dropdown-item>
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_municipalities', filename: 'Аналитика_по_ГРБС_МО', filter, thousands: false })"> По ГРБС / Муниципалитетам, руб. </b-dropdown-item>
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_municipalities', filename: 'Аналитика_по_ГРБС_МО', filter, thousands: true })"> По ГРБС / Муниципалитетам, тыс.руб. </b-dropdown-item>
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_providers', filename: 'Аналитика_по_поставщикам', filter, thousands: false })"> По поставщикам, руб. </b-dropdown-item>
                <b-dropdown-item @click="downloadReportWithFilter({ report: 'download_report_providers', filename: 'Аналитика_по_поставщикам', filter, thousands: true })"> По поставщикам, тыс.руб. </b-dropdown-item>
                <b-dropdown-item v-if="!isExternal" @click="downloadReportWithFilter({ report: 'download_report_offers_rejection', filename: 'Отклонение_предложений', filter, thousands: false })"> Отклонение предложений, руб. </b-dropdown-item>
                <b-dropdown-item v-if="!isExternal" @click="downloadReportWithFilter({ report: 'download_report_offers_rejection', filename: 'Отклонение_предложений', filter, thousands: true })"> Отклонение предложений, тыс.руб. </b-dropdown-item>
                <b-dropdown-item
                    @click="
                        downloadReportWithFilter({
                            report: 'download_report_by_purchase_category',
                            filename: 'Аналитика_по_предметам_закупки',
                            filter,
                            thousands: false,
                        })
                    ">
                    По предметам закупки, руб.
                </b-dropdown-item>
                <b-dropdown-item
                    @click="
                        downloadReportWithFilter({
                            report: 'download_report_by_purchase_category',
                            filename: 'Аналитика_по_предметам_закупки',
                            filter,
                            thousands: true,
                        })
                    ">
                    По предметам закупки, тыс.руб.
                </b-dropdown-item>
            </b-dropdown>
        </b-overlay>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'PurchaseAnalyticsExportDropdown',
    props: {
        filter: {
            type: Object,
        },
        isExternal: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions('analytics', ['downloadReportWithFilter']),
    },
    computed: {
        ...mapGetters('analytics', ['reportLoading']),
        purchaseFilter() {
            return {
                year: this.filter.year,
                purchase_start: {
                    from: this.filter.purchase_start.from,
                    to: this.filter.purchase_start.to,
                },
                municipalities: this.filter.municipalities,
                budgetary_managers: this.filter.budgetary_managers,
            };
        },
    },
};
</script>
