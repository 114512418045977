<template>
    <b-navbar :variant="getLayoutNavbarBg()" toggleable="lg" class="layout-navbar align-items-lg-center container-p-x">
        <!-- Brand demo (see demo.css) -->
        <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
            <span class="app-brand-logo demo bg-primary">
                <!--        <img :src="`${baseUrl}img/logo.png`" />-->
            </span>
            <span class="app-brand-text demo font-weight-normal ml-2"> {{ systemThemeName }} </span>
        </b-navbar-brand>

        <!-- Sidenav toggle (see demo.css) -->
        <b-navbar-nav v-if="sidenavToggle" class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <a @click="toggleSidenav" class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)">
                <i class="ion ion-md-menu text-large align-middle" />
            </a>
        </b-navbar-nav>

        <b-navbar-toggle target="app-layout-navbar" />

        <b-collapse id="app-layout-navbar" is-nav>
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2" />

            <b-navbar-nav class="align-items-lg-center ml-auto">
                <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
                    <template slot="button-content">
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                            <img :src="`/img/avatars/user.png`" alt class="d-block ui-w-30 rounded-circle" />
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">
                                {{ currentUser.name }}
                            </span>
                        </span>
                    </template>

                    <b-dd-item @click="logoutClick()"> <i class="ion ion-ios-log-out text-danger" /> &nbsp; Выйти </b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: 'app-layout-navbar',

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true,
        },
        currentUser: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        toggleSidenav() {
            this.layoutHelpers.toggleCollapsed();
        },

        getLayoutNavbarBg() {
            return this.layoutNavbarBg;
        },

        logoutClick() {
            this.$store.dispatch('logout').then(() => {
                window.location.assign('/login');
            });
        },
    },
};
</script>
