<template>
    <div class="py-2 text-right">
        <b-row>
            <b-col>
                <slot></slot>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'FormActionButtonsGroup',
};
</script>
