<template>
    <div class="py-2">
        <b-row>
            <b-col md="6"><h4>Спецификации</h4></b-col>
            <b-col md="6">
                <b-button @click="showSpec = true" variant="success" class="float-right" size="sm"> Добавить спецификацию </b-button>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div :class="v.$invalid ? 'is-invalid' : ''">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th width="150px">Рег.номер ТРУ</th>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="100px">Код ОКПД2</th>
                                <th width="300px">Характеристики ТРУ</th>
                                <th width="100px">Количество</th>
                                <th width="100px" nowrap>Ед.изм</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in specs" :key="index">
                                <td class="align-text-top">{{ item.number }}</td>
                                <td class="align-text-top">{{ item.ktru ? item.ktru.reg_number : '&mdash;' }}</td>
                                <td class="align-text-top">{{ item.name }}</td>
                                <td class="align-text-top">{{ item.okpd ? item.okpd.code : '&mdash;' }}</td>
                                <td class="align-text-top">
                                    <template v-if="item.ktru">
                                        <b-button v-b-toggle="'collapse-' + index" class="btn-xs btn-custom-green">{{ visible[index] ? 'Скрыть' : 'Показать' }} характеристики</b-button>
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="mt-2">
                                            <ul>
                                                <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.Name }}</b> - {{ spec.Value }}
                                                </li>
                                            </ul>
                                        </b-collapse>
                                    </template>
                                    <template v-else>&mdash;</template>
                                </td>
                                <td style="text-align: right" class="align-text-top">{{ item.count }}</td>
                                <td class="align-text-top">{{ item.okei.local_symbol }}</td>
                                <td nowrap class="align-text-top">
                                    <b-button @click="removeSpec(index)" variant="danger" size="sm"> Удалить </b-button>
                                </td>
                            </tr>
                            <tr v-if="specs.length === 0">
                                <td colspan="8" align="center">Не добавлено спецификаций</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="v.$invalid" class="text-danger">Заполните спецификации</p>
            </b-col>
        </b-row>
        <add-spec-modal :show="showSpec" @cancel="cancelSpecModal" @add="addSpec"></add-spec-modal>
    </div>
</template>

<script>
import AddSpecModal from '@/elements/modals/add-spec-modal';

export default {
    name: 'FormRowSpecificationTable',
    props: {
        value: {
            type: Array,
            required: true,
        },
        grey: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
            required: true,
        },
    },
    components: {
        AddSpecModal,
    },
    data() {
        return {
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 1,
                okei: null,
                ktru_item_id: null,
                ktru: null,
            },
            showSpec: false,
            tmpSpecs: [],
            visible: [],
        };
    },
    methods: {
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 1, okei: null, okpd: null, ktru_item_id: null, ktru: null };
            this.showSpec = false;
        },
        addSpec(spec) {
            this.tmpSpecs = this.specs;
            if (spec.name.length < 3 || spec.count === 0 || !spec.okei || !spec.okpd) {
                this.pushToast({
                    text: 'Неверный формат спецификации',
                    title: 'Ошибка',
                    variant: 'danger',
                });
            } else {
                this.showSpec = false;
                let newSpec = JSON.parse(JSON.stringify(spec)); // clone
                newSpec['number'] = this.tmpSpecs.length + 1;
                newSpec['order'] = this.tmpSpecs.length + 1;
                this.tmpSpecs.push(newSpec);
                this.specs = this.tmpSpecs;
            }
        },
        removeSpec(index) {
            this.tmpSpecs = this.specs;
            this.tmpSpecs.splice(index, 1);
            let startIndex = 1;
            this.tmpSpecs.forEach((item) => {
                item.number = startIndex;
                item.order = startIndex;
                startIndex++;
            });
            this.specs = this.tmpSpecs;
        },
    },
    computed: {
        specs: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}
.table {
    margin-bottom: 0;
}
</style>
