import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import axios from 'axios';

import BootstrapVue from 'bootstrap-vue';
import globals from './globals';
import Popper from 'popper.js';
import Multiselect from 'vue-multiselect';
import DateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Fragment from 'vue-fragment';
import Vuelidate from 'vuelidate';
import { $api } from '@/services';
import globalDictionariesMixin from '@/mixins/globalDictionaries';
import VCalendar from 'v-calendar';

import LoadingCard from '@/elements/loading-card';
import FileUploader from '@/elements/file-uploader';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + '/api/admin/v1';

Vue.prototype.$http = axios;

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePagination from '@/elements/vuetable-pagination-bootstrap-4';

import mergeDeep from '@/mixins/mergeDeep';
import formatMixins from '@/mixins/formatMixins';
import toastMixin from '@/mixins/toastMixin';
import vuetable2Config from '@/mixins/vuetable2Config';

import Toast from '@/elements/toast';
import FilterText from '@/elements/filter-text';
import FilterDateRange from '@/elements/filter-date-range';
import FilterRadio from '@/elements/filter-radio';
import FilterSelectSingle from '@/elements/filter-select-single';
import FilterSelectMultiple from '@/elements/filter-select-multiple';
import FilterSelectMultipleWithAllButton from '@/elements/filter-select-multiple-with-all-button';
import TextRow from '@/elements/text-row';
import TextRowDate from '@/elements/text-row-date';
import TextRowDocuments from '@/elements/text-row-documents';
import TextRowLink from '@/elements/text-row-link';
import TextRowMainDocument from '@/elements/text-row-main-document';
import TextRowPrice from '@/elements/text-row-price';
import TextRowSingleDocument from '@/elements/text-row-single-document';
import TextRowSpecification from '@/elements/text-row-specification';
import FormRowText from '@/elements/form-row-text';
import FormRowActionButtons from '@/elements/form-row-action-buttons';
import FormRowSingleSelect from '@/elements/form-row-single-select';
import FormRowEditDate from '@/elements/form-row-edit-date';
import FormRowEditText from '@/elements/form-row-edit-text';
import FormRowEditPhone from '@/elements/form-row-edit-phone';
import FormRowEditPrice from '@/elements/form-row-edit-price';
import FormRowMultiSelect from '@/elements/form-row-multi-select';
import FormRowEditTextarea from '@/elements/form-row-edit-textarea';
import FormRowEditDatetime from '@/elements/form-row-edit-datetime';
import FormRowEditCheckbox from '@/elements/form-row-edit-checkbox';
import constMixin from '@/mixins/constMixin';
import ShieldGreen from '@/elements/shield-green';
import ShieldYellow from '@/elements/shield-yellow';
import ShieldRed from '@/elements/shield-red';
import FormActionButtonsGroup from '@/elements/form-action-buttons-group';
import FormContentGroup from '@/elements/form-content-group';
import FormRowSpecificationTable from '@/elements/form-row-specification-table';
import AddSpecModal from '@/elements/modals/add-spec-modal';
import KtruSelectModal from '@/elements/modals/ktru-select-modal';
import FormRowSingleDocumentUpload from '@/elements/form-row-single-document-upload';
import FormRowInviteProvidersSelect from '@/elements/form-row-invite-providers-select';
import FilterSearchMultipleCustomers from '@/elements/filter-search-multiple-customers';
import PurchaseAnalyticsExportDropdown from '@/elements/purchase-analytics-export-dropdown';

Vue.use(BootstrapVue);
Vue.use(Fragment.Plugin);
Vue.use(Vuelidate);
Vue.use(VCalendar);

Vue.component('multiselect', Multiselect);
Vue.component('loading-card', LoadingCard);
Vue.component('file-uploader', FileUploader);
Vue.component('toast', Toast);
Vue.component('vuetable', Vuetable);
Vue.component('vuetable-pagination', VuetablePagination);
Vue.component('date-time-picker', DateTimePicker);

Vue.component('filter-text', FilterText);
Vue.component('filter-date-range', FilterDateRange);
Vue.component('filter-radio', FilterRadio);
Vue.component('filter-select-single', FilterSelectSingle);
Vue.component('filter-select-multiple', FilterSelectMultiple);
Vue.component('filter-select-multiple-with-all-button', FilterSelectMultipleWithAllButton);
Vue.component('filter-search-multiple-customers', FilterSearchMultipleCustomers);

Vue.component('text-row', TextRow);
Vue.component('text-row-date', TextRowDate);
Vue.component('text-row-documents', TextRowDocuments);
Vue.component('text-row-link', TextRowLink);
Vue.component('text-row-main-document', TextRowMainDocument);
Vue.component('text-row-price', TextRowPrice);
Vue.component('text-row-specification', TextRowSpecification);
Vue.component('text-row-single-document', TextRowSingleDocument);

Vue.component('form-action-buttons-group', FormActionButtonsGroup);
Vue.component('form-content-group', FormContentGroup);
Vue.component('form-row-text', FormRowText);
Vue.component('form-row-action-buttons', FormRowActionButtons);
Vue.component('form-row-single-select', FormRowSingleSelect);
Vue.component('form-row-multi-select', FormRowMultiSelect);
Vue.component('form-row-edit-text', FormRowEditText);
Vue.component('form-row-edit-phone', FormRowEditPhone);
Vue.component('form-row-edit-price', FormRowEditPrice);
Vue.component('form-row-edit-textarea', FormRowEditTextarea);
Vue.component('form-row-edit-date', FormRowEditDate);
Vue.component('form-row-edit-datetime', FormRowEditDatetime);
Vue.component('form-row-edit-checkbox', FormRowEditCheckbox);
Vue.component('form-row-specification-table', FormRowSpecificationTable);
Vue.component('form-row-single-document-upload', FormRowSingleDocumentUpload);
Vue.component('form-row-invite-providers-select', FormRowInviteProvidersSelect);

Vue.component('add-spec-modal', AddSpecModal);
Vue.component('ktru-select-modal', KtruSelectModal);

Vue.component('purchase-analytics-export-dropdown', PurchaseAnalyticsExportDropdown);

Vue.component('shield-green', ShieldGreen);
Vue.component('shield-yellow', ShieldYellow);
Vue.component('shield-red', ShieldRed);

Vue.prototype.$http.interceptors.request.use(
    async function (config) {
        if (!config.url.includes('auth/login') && !config.url.includes('auth/recall')) {
            config.headers['Authorization'] = 'Bearer ' + store.getters['token'];
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

Vue.prototype.$http.interceptors.response.use(undefined, function (error) {
    if (error.request.status !== 401) {
        return Promise.reject(error);
    }
    if (error.request.responseURL.includes('auth/login') || error.request.responseURL.includes('auth/logout')) {
        return Promise.reject(error);
    }
    if (error.request.responseURL.includes('auth/recall')) {
        store.dispatch('logout');
        // window.location.assign('/login');
    }
    if (error.request.responseURL.includes('auth/logout') && error.request.status === 401) {
        window.location.assign('/login');
    }
});
// Global RTL flag
Vue.mixin({
    data: globals,
    components: {
        Vuetable,
        VuetablePagination,
    },
    computed: {
        $api: () => $api,
    },
});
Vue.mixin(vuetable2Config);
Vue.mixin(mergeDeep);
Vue.mixin(formatMixins);
Vue.mixin(toastMixin);
Vue.mixin(constMixin);
Vue.mixin(globalDictionariesMixin);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
