export default {
    methods: {
        isObject(item) {
            return item && typeof item === 'object' && !Array.isArray(item);
        },
        /**
         * Deep merge two objects.
         * @param target
         * @param source
         */
        mergeDeep(target, source) {
            if (this.isObject(target) && this.isObject(source)) {
                for (const key in source) {
                    if (this.isObject(source[key])) {
                        if (!target[key]) Object.assign(target, { [key]: {} });
                        this.mergeDeep(target[key], source[key]);
                    } else {
                        Object.assign(target, { [key]: source[key] });
                    }
                }
            }
            return target;
        },
    },
};
