import { ModelApiService } from '@/services/base';
import axios from 'axios';

export class OrganizationsApiService extends ModelApiService {
    constructor() {
        super('organizations');
    }

    async find(data) {
        try {
            const response = await axios.post(this.getUrl('find'), data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
