import { REFUND_REQUEST } from '@/const';

export default {
    methods: {
        $getBooleanValue(value) {
            switch (value.toLowerCase()) {
                case 'true':
                case true:
                case 1:
                case '1':
                    return true;
                case 'false':
                case false:
                case 0:
                case '0':
                default:
                    return false;
            }
        },
    },
    computed: {
        _REFUND_REQUEST() {
            return REFUND_REQUEST;
        },
        systemThemeName() {
            return process.env.VUE_APP_SYSTEM_THEME_NAME ?? 'ЕСТ';
        },
        systemThemeLimitsEnabled() {
            return this.$getBooleanValue(process.env.VUE_APP_SYSTEM_THEME_LIMITS_ENABLED);
        },
    },
};
