<template>
    <div class="authentication-wrapper authentication-1 px-4">
        <div class="authentication-inner py-5">
            <!-- Form -->
            <form @submit.prevent="handleSubmit" class="my-5">
                <b-form-group label="Email">
                    <b-input v-model="credentials.email" />
                </b-form-group>
                <b-form-group>
                    <div slot="label" class="d-flex justify-content-between align-items-end">
                        <div>Пароль</div>
                    </div>
                    <b-input v-model="credentials.password" type="password" />
                </b-form-group>

                <div class="text-center m-0">
                    <b-btn variant="primary" type="submit"> Войти </b-btn>
                </div>
            </form>
        </div>
    </div>
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>

<script>
export default {
    name: 'Login',
    metaInfo: {
        title: 'Авторизация',
    },
    data: () => ({
        credentials: {
            email: '',
            password: '',
        },
    }),
    methods: {
        handleSubmit() {
            this.$store
                .dispatch('login', {
                    email: this.credentials.email,
                    password: this.credentials.password,
                })
                .then(() => {
                    this.$router.push('/');
                });
        },
    },
};
</script>
