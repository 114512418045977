<template>
    <b-row class="py-2" no-gutters>
        <b-col md="12" class="fs-14 grey-color">
            <p class="font-weight-bold">Спецификация:</p>
            <table class="table">
                <thead>
                    <tr>
                        <th width="50px">№</th>
                        <th width="150px">Рег.номер ТРУ</th>
                        <th>Наименование товара (работ, услуг)</th>
                        <th width="100px">Код ОКПД2</th>
                        <th width="300px">Характеристики ТРУ</th>
                        <th width="100px">Количество</th>
                        <th width="100px">Ед.изм</th>
                        <th width="100px" v-if="withPrice">Цена за ед., руб.</th>
                        <th width="100px" v-if="withPrice">Стоимость, руб.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in value" :key="item.id" v-bind="item">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <template v-if="!!item.ktru_item_id">
                                <span :id="'spec-ktru-popover-' + (index + 1)">{{ item.ktru_item_reg_number }}</span>
                                <b-popover :target="'spec-ktru-popover-' + (index + 1)" triggers="hover" placement="top">
                                    <template v-slot:title><b>Рег.номер ТРУ:</b> {{ item.ktru_item_reg_number }}</template>
                                    <b>Группа ТРУ:</b> {{ item.ktru_item_group_name }}<br />
                                    <b>Наименование:</b> {{ item.ktru_item_name }}<br />
                                    <b>Тип:</b> {{ item.ktru_item_type_name }}<br />
                                </b-popover>
                            </template>
                            <template v-else>&mdash;</template>
                        </td>
                        <td>{{ item.name }}</td>
                        <td class="align-text-top">{{ item.okpd_id ? item.okpd_name : '&mdash;' }}</td>
                        <td class="align-text-top">
                            <template v-if="item.ktru_item_specs">
                                <b-button v-b-toggle="'collapse-' + index" class="btn-xs btn-custom-green">{{ visible[index] ? 'Скрыть' : 'Показать' }} характеристики</b-button>
                                <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="mt-2">
                                    <ul>
                                        <li v-for="(spec, specIndex) of item.ktru_item_specs" :key="index + '_spec_' + specIndex">
                                            <b>{{ spec.Name }}</b> - {{ spec.Value }}
                                        </li>
                                    </ul>
                                </b-collapse>
                            </template>
                            <template v-else>&mdash;</template>
                        </td>
                        <td style="text-align: right">{{ item.count }}</td>
                        <td style="text-align: right">
                            <template v-if="item.okei_id">{{ item.okei_name }}</template>
                            <template v-else>&mdash;</template>
                        </td>
                        <td style="text-align: right" v-if="withPrice">{{ $formatPrice(item.price) }}</td>
                        <td style="text-align: right" v-if="withPrice">{{ $formatPrice(item.count * item.price) }}</td>
                    </tr>
                </tbody>
            </table>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'text-row-specification',
    props: {
        value: {
            type: Array,
            default: undefined,
        },
        withPrice: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: [],
        };
    },
};
</script>
