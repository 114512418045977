import { ModelApiService } from '@/services/base';
import axios from 'axios';

export class RefundRequestsApiService extends ModelApiService {
    constructor() {
        super('refund_requests');
    }

    async accept(id) {
        try {
            const response = await axios.post(this.getUrl(id) + '/accept');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async reject(id, reject_comment) {
        try {
            const response = await axios.post(this.getUrl(id) + '/reject', { reject_comment });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
