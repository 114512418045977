<template>
    <div>
        <input type="file" id="fileload" ref="fileload" v-on:change="onChangeFileUpload" style="display: none" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
        <b-button @click="fileClick" variant="custom-unstyle-grey" :disabled="isDisabled">
            <img src="/img/clip_green.svg" alt="" class="mr-2" />
            <slot></slot>
        </b-button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FileUploader',
    props: {
        isMain: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            file: null,
            returnFile: null,
        };
    },
    methods: {
        fileClick() {
            this.$refs.fileload.value = '';
            this.file = null;
            this.returnFile = null;
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            this.$refs.fileload.dispatchEvent(evt);
        },
        submitForm() {
            let formData = new FormData();
            formData.append('file', this.file);
            axios
                .post('/documents', formData)
                .then((resp) => {
                    this.returnFile = resp.data;
                    this.returnFile.isMain = this.$props.isMain;
                    this.sendFile();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendFile() {
            this.$emit('set_file', this.returnFile);
        },
        onChangeFileUpload() {
            if (this.$refs.fileload.files.length > 0) {
                this.file = this.$refs.fileload.files[0];
                this.submitForm();
            }
        },
    },
};
</script>
