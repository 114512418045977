import { ModelApiService } from '@/services/base';
import axios from 'axios';
import store from '@/store';

export class UsersApiService extends ModelApiService {
    constructor() {
        super('users');
    }

    async getSelectItems() {
        try {
            const response = await axios.get(this.getUrl('get_select_items'));
            return await response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async block(id) {
        try {
            const response = await axios.put(this.getUrl(id) + '/block');
            await store.dispatch('showSuccessToast', 'Пользователь успешно заблокирован').then(() => {});
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async unblock(id) {
        try {
            const response = await axios.put(this.getUrl(id) + '/unblock');
            await store.dispatch('showSuccessToast', 'Пользователь успешно разблокирован').then(() => {});
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        try {
            await axios.delete(this.getUrl(id));
            await store.dispatch('showSuccessToast', 'Пользователь успешно удален').then(() => {});
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
