import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import globals from '@/globals';
import store from '@/store';

import analyticsRoutes from './analytics';
import usersRoutes from '@/router/users';
import adminsRoutes from '@/router/admins';
import organizationsRoutes from '@/router/organizations';
import competitivePurchasesRoutes from '@/router/competitive-purchases';
import directPurchasesRoutes from '@/router/direct-purchases';
import simplePurchasesRoutes from '@/router/simple-purchases';
import accountRoutes from '@/router/account';
import calendarRoutes from '@/router/calendar';
import Login from '@/components/auth/login';

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: { requiresVisitor: true },
    },
    ...analyticsRoutes,
    ...organizationsRoutes,
    ...competitivePurchasesRoutes,
    ...directPurchasesRoutes,
    ...simplePurchasesRoutes,
    ...usersRoutes,
    ...adminsRoutes,
    ...accountRoutes,
    ...calendarRoutes,
    { path: '*', redirect: '/' },
];

const router = new Router({
    base: '/',
    mode: 'history',
    routes: ROUTES,
});

const userRoleIsPermitted = (userRoles) => {
    if (typeof store.state.user.role !== 'undefined') {
        return userRoles.includes(store.state.user.role);
    } else {
        return false;
    }
};

router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0);
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath,
                },
            });
        } else {
            if (to.meta.roles) {
                if (userRoleIsPermitted(to.meta.roles)) {
                    next();
                } else {
                    next({ path: '/' });
                }
            } else {
                next();
            }
        }
    } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
        if (store.getters.isLoggedIn) {
            next({
                path: '/',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
