import axios from 'axios';
import FileDownload from 'js-file-download';

export default {
    downloadFile(id, name) {
        axios({
            url: '/purchase/file_download/' + id,
            method: 'GET',
            responseType: 'blob', // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    },
    documentDownload(file) {
        axios.get('/documents/' + file.id, { responseType: 'blob' }).then((response) => {
            FileDownload(response.data, file.name);
        });
    },
    commonDocumentDownload(file) {
        if (file.type === 's3') {
            this.documentDownload(file);
        } else {
            axios.post('/documents/download_local', { path: file.path }, { responseType: 'blob' }).then((response) => {
                FileDownload(response.data, file.name);
            });
        }
    },
};
