import Layout from '@/layout/Layout.vue';
import { AdminRole } from '@/globals';

export default [
    {
        path: '/account',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account.vue'),
                name: 'Account',
                meta: { requiresAuth: true, roles: [AdminRole.SUPER] },
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "account" */ '@/components/account/account-show'),
                name: 'AccountShow',
                meta: { requiresAuth: true, roles: [AdminRole.SUPER] },
            },
        ],
    },
];
