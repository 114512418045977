import { OrganizationsApiService } from '@/services/organizations';
import { OrganizationAccountsApiService } from '@/services/organization-accounts';
import { UsersApiService } from '@/services/users';
import { CompetitivePurchasesApiService } from '@/services/competitive-purchases';
import { DirectPurchasesApiService } from '@/services/direct-purchases';
import { SimplePurchasesApiService } from '@/services/simple-purchases';
import { RefundRequestsApiService } from '@/services/refund-requests';

export const $api = {
    organizations: new OrganizationsApiService(),
    users: new UsersApiService(),
    competitivePurchases: new CompetitivePurchasesApiService(),
    directPurchases: new DirectPurchasesApiService(),
    simplePurchases: new SimplePurchasesApiService(),
    organizationAccounts: new OrganizationAccountsApiService(),
    refundRequests: new RefundRequestsApiService(),
};
