<template>
    <b-row no-gutters>
        <b-form-group :label="label + ':'" :state="v ? state : null" class="w-100" :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
            <b-form-textarea v-model="text" :state="v ? state : null" :rows="rows"></b-form-textarea>
            <template v-if="v && !state">
                <b-form-invalid-feedback style="display: block !important">Поле обязательно для заполнения</b-form-invalid-feedback>
            </template>
        </b-form-group>
    </b-row>
</template>

<script>
export default {
    name: 'FormRowEditTextarea',
    props: {
        value: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'label',
        },
        rows: {
            type: Number,
            default: 5,
        },
        labelColsSm: {
            type: Number,
            default: 4,
        },
        labelColsLg: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
    },
};
</script>
