<template>
    <b-col md="4">
        <b-form-group :label="label" :description="description">
            <multiselect v-model="text" :options="options" :multiple="true" :allow-empty="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :show-labels="false" :placeholder="placeholder" :label="labelField" :track-by="trackBy" :disabled="disabled" :id="id">
                <template slot="clear">
                    <div class="multiselect__clear" v-if="text.length > 0" @mousedown.prevent.stop="clearAll()"></div>
                </template>
            </multiselect>
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'filter-select-multiple',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    methods: {
        clearAll() {
            this.text = [];
        },
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            },
        },
        id() {
            return 'filter-select-multiple_' + this._uid;
        },
    },
};
</script>
