<template>
    <div :style="'order:' + toast.id" class="m-2 toast_parent">
        <div>
            <div :class="'toast-' + toast.variant" class="toast_head">
                {{ toast.title }}
                <i class="close_btn" @click="remove">
                    <span></span>
                    <span></span>
                </i>
            </div>
            <div class="toast_body">
                <div>
                    <p v-if="toast.boldText" class="boldText m-0">
                        {{ toast.boldText }}
                    </p>
                    <span v-if="toast.text" class="text">
                        {{ toast.text }}
                    </span>
                    <span v-if="toast.linkText" class="text mt-2" style="display: block" v-html="toast.linkText"> </span>
                </div>
                <router-link v-if="toast.link" :to="toast.link" class="fs-14 green-color">подробнее...</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'Toast',
    props: ['toast'],
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        if (this.toast.timer) {
            this.timeout = setTimeout(this.remove, this.toast.timer);
        }
    },
    methods: {
        remove() {
            this.removeToast(this.toast.id);
            clearTimeout(this.timeout);
        },
        ...mapActions({
            removeToast: 'removeToast',
        }),
    },
};
</script>

<style scoped>
* {
    --green-color: #26b89a;
    --orange-color: #40acb4;
    --grey-color: #959ba4;
    --greyBg-color: #f5f5f7;
    --white-color: #fff;
    --halfWhite-color: rgba(255, 255, 255, 0.5);

    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
}

.toast-orange {
    background-color: #f6b33a !important;
}

.toast-red {
    background-color: #ff7888 !important;
}

.toast-green {
    background-color: var(--green-color) !important;
}

.toast_head {
    min-height: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    color: #ffffff;
    padding: 7px 15px;
    padding-right: 30px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    position: relative;
    background-color: var(--grey-color);
}

.toast_body {
    padding: 12px 15px;
}

.boldText {
    color: var(--green-color);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.toast_parent {
    max-width: 320px;
    background-color: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
}

.text {
    font-size: 12px;
    color: var(--grey-color);
}

.close_btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.close_btn span {
    display: block;
    width: 13px;
    height: 2px;
    background: #ffffff8a;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.close_btn span {
    -webkit-transform: rotate(-45deg) translateY(1.5px);
    -moz-transform: rotate(-45deg) translateY(1.5px);
    -ms-transform: rotate(-45deg) translateY(1.5px);
    -o-transform: rotate(-45deg) translateY(1.5px);
    transform: rotate(-45deg) translateY(1.5px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}

.close_btn span:nth-child(2) {
    -webkit-transform: rotate(45deg) translateY(-1.5px);
    -moz-transform: rotate(45deg) translateY(-1.5px);
    -ms-transform: rotate(45deg) translateY(-1.5px);
    -o-transform: rotate(45deg) translateY(-1.5px);
    transform: rotate(45deg) translateY(-1.5px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.green-color {
    color: #26b89a;
}
</style>
