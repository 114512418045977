<template>
    <b-row no-gutters>
        <b-form-group :label="label" :description="description" :state="v ? state : null" class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
            <v-date-picker v-model="date" is-required timezone="Asia/Dhaka" class="vcalendar" :class="v ? (!state ? 'is-invalid' : 'is-valid') : null">
                <template v-slot="{ inputValue, togglePopover }">
                    <b-input class="bg-white border px-2 py-1 rounded field-input" :class="v ? (!state ? 'is-invalid' : 'is-valid') : null" :value="inputValue" :placeholder="placeholder" @click="togglePopover()" readonly />
                    <template v-if="v && !state">
                        <b-form-invalid-feedback style="display: block !important">Поле обязательно для заполнения</b-form-invalid-feedback>
                    </template>
                </template>
            </v-date-picker>
        </b-form-group>
    </b-row>
</template>

<script>
export default {
    name: 'FormRowEditDate',
    props: {
        value: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'label',
        },
        minDate: {
            type: String,
            default: null,
        },
        maxDate: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Выберите дату',
        },
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
        id() {
            return 'form-row-edit-date_' + this._uid;
        },
    },
};
</script>
