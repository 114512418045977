const defaultAccountDetailsFilter = {
    date_from: null,
    date_to: null,
};

const defaultPagination = {
    sortOrder: [{ field: 'id', direction: 'asc', sortField: 'id' }],
    page: 1,
};

const accountDetailsModule = {
    namespaced: true,
    state: {
        filter: {
            ...defaultAccountDetailsFilter,
            ...JSON.parse(localStorage.getItem('accountDetailsFilter')),
        },
        pagination: {
            ...defaultPagination,
            ...JSON.parse(localStorage.getItem('accountDetailsPagination')),
        },
    },
    mutations: {
        resetFilter(state) {
            state.filter = defaultAccountDetailsFilter;
        },
        resetPagination(state) {
            state.pagination = defaultPagination;
        },
        updateFilter(state, filter) {
            state.filter = filter;
            localStorage.setItem('accountDetailsFilter', JSON.stringify(filter));
        },
        updatePagination(state, pagination) {
            state.pagination = pagination;
            localStorage.setItem('accountDetailsPagination', JSON.stringify(pagination));
        },
    },
};

export default accountDetailsModule;
