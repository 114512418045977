import Layout from '@/layout/Layout.vue';
import { AdminRole } from '@/globals';

export default [
    {
        path: '/competitive_purchases',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "purchases" */ '@/components/competitive-purchases/competitive-purchases-list'),
                name: 'CompetitivePurchasesList',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "purchases" */ '@/components/competitive-purchases/competitive-purchase-show'),
                name: 'CompetitivePurchaseShow',
                meta: {
                    requiresAuth: true,
                    roles: [AdminRole.SUPER],
                },
            },
        ],
    },
];
