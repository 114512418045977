import { ModelApiService } from '@/services/base';
import axios from 'axios';
import FileDownload from 'js-file-download';

export class CompetitivePurchasesApiService extends ModelApiService {
    constructor() {
        super('competitive_purchases');
    }

    async getXlsExport(exportFilter) {
        try {
            const response = await axios.post(this.getUrl('xls_export'), exportFilter, { responseType: 'blob', params: exportFilter });
            FileDownload(response.data, 'Конкурсы_' + exportFilter.purchase_start.from + '_' + exportFilter.purchase_start.to + '.xlsx');
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
