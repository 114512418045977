import Layout from '@/layout/Layout.vue';
import { AdminRole } from '@/globals';

export default [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "analytics" */ '@/views/analytics-dashboard'),
                name: 'AnalyticsDashboard',
                meta: { requiresAuth: true, roles: [AdminRole.SUPER] },
            },
        ],
    },
];
