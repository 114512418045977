const defaultSimplePurchasesFilter = {
    purchase_start: {
        from: null,
        to: null,
    },
    reg_number: '',
    deliverable_groups: [],
    status: [],
    customer: '',
};

const defaultPagination = {
    sortOrder: [{ field: 'id', direction: 'asc', sortField: 'id' }],
    page: 1,
};

const simplePurchasesModule = {
    namespaced: true,
    state: {
        filter: {
            ...defaultSimplePurchasesFilter,
            ...JSON.parse(localStorage.getItem('simplePurchasesFilter')),
        },
        pagination: {
            ...defaultPagination,
            ...JSON.parse(localStorage.getItem('simplePurchasesPagination')),
        },
    },
    mutations: {
        resetFilter(state) {
            state.filter = defaultSimplePurchasesFilter;
        },
        resetPagination(state) {
            state.pagination = defaultPagination;
        },
        updateFilter(state, filter) {
            state.filter = filter;
            localStorage.setItem('simplePurchasesFilter', JSON.stringify(filter));
        },
        /**
         * @param {any} state
         * @param {any} pagination
         */
        updatePagination(state, pagination) {
            state.pagination = pagination;
            localStorage.setItem('simplePurchasesPagination', JSON.stringify(pagination));
        },
    },
    getters: {
        filter: (state) => state.filter,
        pagination: (state) => state.pagination,
    },
};

export default simplePurchasesModule;
