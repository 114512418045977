import { BaseApiService } from '@/services/base';
import axios from 'axios';

export class OrganizationAccountsApiService extends BaseApiService {
    constructor() {
        super('organization_accounts');
    }

    async list(params) {
        try {
            const response = await axios.get(this.getUrl(), { params });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async summary() {
        try {
            const response = await axios.get(this.getUrl('summary'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async replenish(organizationId, amount) {
        try {
            const response = await axios.post(this.getUrl(organizationId), { amount });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
