<template>
    <div class="py-2">
        <h4>Приглашение поставщиков</h4>
        <b-row class="py-3">
            <b-col md="3" class="d-flex align-items-center">
                <span class="fs-14 grey-color fw-700">Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к участию</span>
            </b-col>
            <b-col md="9" class="fs-14 grey-color">
                <multiselect
                    :options="organizations"
                    v-model="emails"
                    :multiple="true"
                    :close-on-select="true"
                    :preserve-search="true"
                    :taggable="true"
                    @tag="addTag"
                    tag-placeholder="Добавить"
                    placeholder="Выберите организации"
                    deselect-label="Отменить"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    label="name"
                    track-by="id"
                    :class="`${v.$invalid ? 'is-invalid' : 'is-valid'}`">
                    <span slot="noOptions">Нет данных</span>
                </multiselect>
                <b-form-invalid-feedback v-if="v.$invalid">Поле не заполнено</b-form-invalid-feedback>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FormRowInviteProvidersSelect',
    props: {
        value: {
            type: Array,
            required: true,
        },
        v: {
            type: Object,
            required: true,
        },
        grey: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            organizations: [],
        };
    },
    async created() {
        await this.getOrganizationsList();
    },
    methods: {
        getOrganizationsList() {
            axios
                .get('/providers/list')
                .then((resp) => {
                    this.organizations = resp.data;
                })
                .catch((error) => console.log(error));
        },
        addTag(newTag) {
            const tmpEmails = this.emails;
            const tag = {
                email: newTag,
                id: newTag,
                name: newTag,
            };
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(tag.email)) {
                tmpEmails.push(tag);
                this.organizations.push(tag);
            }
            this.emails = tmpEmails;
        },
        clearEmails() {
            this.form.emails = [];
        },
    },
    computed: {
        emails: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
