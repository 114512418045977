<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label" :label-for="id" :state="v ? state : null" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <multiselect v-model="text" :options="options" :multiple="true" :allow-empty="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :show-labels="false" :placeholder="placeholder" :label="labelField" :track-by="trackBy" :disabled="disabled" />
        <template v-if="v && !state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-multi-select',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        v: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            },
        },
        id() {
            return 'filter-select-multiple_' + this._uid;
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        },
    },
};
</script>
