import store from '@/store';
import axios from 'axios';

export class BaseApiService {
    baseUrl = process.env.VUE_APP_API_BASE_URL + '/api/admin/v1';
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        return `${this.baseUrl}/${this.resource}/${id}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}

export class ReadOnlyApiService extends BaseApiService {
    constructor(resource) {
        super(resource);
    }

    async fetch() {
        try {
            const response = await axios.get(this.getUrl());
            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        try {
            if (!id) throw Error('Id is not provided');
            const response = await axios.get(this.getUrl(id));
            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
    async getHistory(id) {
        try {
            if (!id) throw Error('Id is not provided');
            const response = await axios.get(this.getUrl(id) + '/history');
            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class ReadOnlyDirectoryApiService extends ReadOnlyApiService {
    constructor(resource) {
        super(resource);
    }

    getUrl(id = '') {
        return `${this.baseUrl}/directories/${this.resource}/${id}`;
    }

    async getSelectItems() {
        try {
            const response = await axios.get(this.getUrl('get_select_items'));
            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class ModelApiService extends ReadOnlyApiService {
    constructor(resource) {
        super(resource);
    }

    async post(data = {}) {
        try {
            const response = await axios.post(this.getUrl(), data);
            const { id } = response.data;
            return id;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async put(id, data = {}) {
        if (!id) throw Error('Id is not provided');
        try {
            const response = await axios.put(this.getUrl(id), data);
            const { id: responseId } = response.data;
            return responseId;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async checkEmail(email) {
        try {
            await axios.post(this.getUrl('check_email'), { email: email });
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.delete(this.getUrl(id));
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class DirectoryModelApiService extends ReadOnlyDirectoryApiService {
    constructor(resource) {
        super(resource);
    }

    async post(data = {}) {
        try {
            const response = await axios.post(this.getUrl(), data);
            const { id } = response.data;
            return id;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async put(id, data = {}) {
        if (!id) throw Error('Id is not provided');
        try {
            const response = await axios.put(this.getUrl(id), data);
            const { id: responseId } = response.data;
            return responseId;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.delete(this.getUrl(id));
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
