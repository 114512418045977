<template>
    <b-col md="4">
        <b-form-group :label="label" :description="description">
            <multiselect
                v-model="text"
                :options="customers"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :placeholder="placeholder"
                :label="labelField"
                :track-by="trackBy"
                :disabled="disabled"
                :show-labels="false"
                :id="id"
                :options-limit="300"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFind">
                <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :title="option[labelField]">
                        <span>{{ option[labelField] }}</span>
                        <i aria-hidden="true" class="multiselect__tag-icon" @click="remove(option)" />
                    </span>
                </template>
            </multiselect>
        </b-form-group>
    </b-col>
</template>

<script>
import debounceMixin from '@/mixins/debounce';

export default {
    name: 'filter-search-multiple-customers',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        placeholder: {
            type: String,
            default: 'Введите ИНН или название организации',
        },
        description: {
            type: String,
            default: '',
        },
    },
    mixins: [debounceMixin],
    data() {
        return {
            isLoading: false,
            customers: [],
            selectedCustomers: [],
        };
    },
    methods: {
        async asyncFind(query) {
            this.debounce(async () => {
                this.isLoading = true;
                this.customers = await this.$api.directories.organizations.findCustomers({ query });
                this.isLoading = false;
            });
        },
    },
    computed: {
        text: {
            get() {
                return this.selectedCustomers ? this.selectedCustomers.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                this.selectedCustomers.splice(0);
                this.selectedCustomers.push(...value);
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            },
        },
        id() {
            return 'filter-search-multiple-customers_' + this._uid;
        },
    },
};
</script>
