<template>
    <div class="mt-5 mb-2 text-right">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'form-row-action-buttons',
};
</script>
