<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <b-form-checkbox :id="id" v-model="text" :state="state" :disabled="disabled" class="fs-14 mt-2"></b-form-checkbox>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowEditCheckbox',
    props: {
        value: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'label',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
        id() {
            return 'form_row_edit_checkbox_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.email && !!this.v.$params.email) return 'Введите валидный email-адрес';
            }
            return '';
        },
    },
};
</script>
