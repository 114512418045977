<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm="8" content-cols-lg="9" :description="description" :label="label" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <multiselect v-model="text" :options="options" :multiple="false" :allow-empty="allowEmpty" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :show-labels="false" :placeholder="placeholder" :label="labelField" :track-by="trackBy" :disabled="disabled">
            <template slot="clear" v-if="allowEmpty">
                <div class="multiselect__clear" v-if="value" @mousedown.prevent.stop="clearAll()"></div>
            </template>
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
        </multiselect>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-single-select',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            default: null,
        },
        v: {
            type: Object,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        clearAll() {
            this.text = null;
        },
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.find((e) => e[this.trackBy] === this.value) : null;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value ? value[this.trackBy] : null);
            },
        },
        id() {
            return 'form_row_single-select_' + this._uid;
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        },
    },
};
</script>
