<template>
    <fragment v-if="asRow">
        <b-col md="4">
            <b-form-group :label="label + ', с'">
                <date-time-picker
                    :id="id_from"
                    v-model="value.from"
                    label="Начало периода"
                    :only-date="true"
                    formatted="DD.MM.YYYY"
                    format="YYYY-MM-DD"
                    no-button-now
                    autoClose
                    :min-date="yearBegin"
                    :max-date="maxDate"
                    @input="onFilterChange"
                    :no-label="!!value.from"
                    :disabled="disabled"></date-time-picker>
            </b-form-group>
        </b-col>
        <b-col md="4">
            <b-form-group :label="label + ', по'">
                <date-time-picker
                    :id="id_to"
                    v-model="value.to"
                    label="Конец периода (включительно)"
                    :only-date="true"
                    formatted="DD.MM.YYYY"
                    format="YYYY-MM-DD"
                    no-button-now
                    autoClose
                    :min-date="minDate"
                    :max-date="yearEnd"
                    @input="onFilterChange"
                    :no-label="!!value.to"
                    :disabled="disabled"></date-time-picker>
            </b-form-group>
        </b-col>
    </fragment>
    <fragment v-else>
        <b-col md="4">
            <b-form-group :label="label + ', с'">
                <date-time-picker
                    :id="id_from"
                    v-model="value.from"
                    label="Начало периода"
                    :only-date="true"
                    formatted="DD.MM.YYYY"
                    format="YYYY-MM-DD"
                    buttonNowTranslation="Сегодня"
                    autoClose
                    :min-date="yearBegin"
                    :max-date="maxDate"
                    @input="onFilterChange"
                    :no-label="!!value.from"
                    :disabled="disabled"></date-time-picker>
            </b-form-group>
            <b-form-group :label="label + ', по'">
                <date-time-picker
                    :id="id_to"
                    v-model="value.to"
                    label="Конец периода (включительно)"
                    :only-date="true"
                    formatted="DD.MM.YYYY"
                    format="YYYY-MM-DD"
                    buttonNowTranslation="Сегодня"
                    autoClose
                    :min-date="minDate"
                    :max-date="yearEnd"
                    @input="onFilterChange"
                    :no-label="!!value.to"
                    :disabled="disabled"></date-time-picker>
            </b-form-group>
        </b-col>
    </fragment>
</template>

<script>
import moment from 'moment';

export default {
    name: 'filter-date-range',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            default: () => ({
                from: null,
                to: null,
            }),
        },
        year: {
            type: Number,
            default: null,
        },
        asRow: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id_from: null,
            id_to: null,
        };
    },
    mounted() {
        this.id_from = this._uid + '_from';
        this.id_to = this._uid + '_to';
    },
    methods: {
        onFilterChange() {
            this.$emit('input', this.value);
        },
    },
    computed: {
        yearBegin() {
            return this.year ? moment([this.year]).startOf('year').format('YYYY-MM-DD') : null;
        },
        yearEnd() {
            return this.year ? moment([this.year]).endOf('year').format('YYYY-MM-DD') : null;
        },
        maxDate() {
            return this.value.to ?? (this.year ? moment([this.year]).endOf('year').format('YYYY-MM-DD') : null);
        },
        minDate() {
            return this.value.from ?? (this.year ? moment([this.year]).startOf('year').format('YYYY-MM-DD') : null);
        },
    },
};
</script>
