export const __PURCHASE_STATUS = {
    status_start: 1,
    status_protocol_formed: 2,
    status_contract_concluded: 3,
    status_contract_executed: 4,
    status_contract_not_executed: 5,
    status_contract_terminated: 6,
    status_cancel: 7,
    status_fail: 8,
    status_control: 9,
    status_contract_not_concluded: 10,
    status_draft: 11,
    status_blocked: 12,
    status_draft_save_signed: 13,
};

export const REFUND_REQUEST = {
    STATUS_NEW: 1,
    STATUS_ACCEPTED: 2,
    STATUS_REJECTED: 3,
};

export const RefundRequestStatus = [
    { id: 1, name: 'Новый' },
    { id: 2, name: 'Исполнен' },
    { id: 3, name: 'Отклонен' },
];

export const OrderTypes = [
    { id: 1, name: 'п.5' },
    { id: 2, name: 'п.4' },
];
