<template>
    <b-card no-body>
        <b-card-body style="height: 100%">
            <div class="sk-cube-grid sk-primary">
                <div class="sk-cube sk-cube1" />
                <div class="sk-cube sk-cube2" />
                <div class="sk-cube sk-cube3" />
                <div class="sk-cube sk-cube4" />
                <div class="sk-cube sk-cube5" />
                <div class="sk-cube sk-cube6" />
                <div class="sk-cube sk-cube7" />
                <div class="sk-cube sk-cube8" />
                <div class="sk-cube sk-cube9" />
            </div>
        </b-card-body>
    </b-card>
</template>

<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>

<script>
export default {
    name: 'LoadingCard',
};
</script>
